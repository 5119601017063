import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { Box, Button, createTheme } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Paper, Popover, Select, TextField, Typography, makeStyles } from '@material-ui/core';
import { HeaderDataTypes } from '../../../util/constants';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      sm: 600
    },
  },
});


const useStyles = makeStyles({
  filterRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  formControl: {
    margin: 5,
    width: "100%"
  },
  resize: {
    [theme_bp.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  dates: {
    display: "flex"
  },
  space: {
    width: 10
  }
});

export default function CustomTableFilters(props) {
  const { headers, filters, updateFilters, handleArchivedToggle, showArchived, ButtonFilters } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modifiedFilters, setModifiedFilters] = useState([{}]);
  const [actionFilter, setActionFilter] = useState(null)
  const [buttonFilters, setButtonFilters] = useState([])

  const isMobile = useMediaQuery({ query: '(max-width: 625px)' });

  const classes = useStyles();

  const toggleArrow = () => {
    return dropdownOpen ? (<ArrowDownIcon />) : (<ArrowRightIcon />);
  };

  const handleFilterClick = (event) => {
    setDropdownOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setDropdownOpen(false);
    setAnchorEl(null);
    updateFilters([...modifiedFilters, ...buttonFilters]);
  };

  const shouldDisableFilter = (index) => {
    switch (modifiedFilters[index]?.header?.type) {
      case (HeaderDataTypes.STRING): return !(modifiedFilters[index]?.header?.id && modifiedFilters[index]?.value);
      case (HeaderDataTypes.NUMBER): return !(modifiedFilters[index]?.header?.id && modifiedFilters[index]?.value);
      case (HeaderDataTypes.DATE): return !(modifiedFilters[index]?.header?.id && modifiedFilters[index]?.from && modifiedFilters[index]?.to);
      default: return false;
    }
  }

  const handleFilterValueChange = (event, index) => {
    modifiedFilters[index].value = event.target.value;
    updateFilters([...modifiedFilters, ...buttonFilters]);
  }

  const handleFilterDateChange = (newValue, index, isFrom) => {
    if (!newValue) return;

    if (isFrom) {
      modifiedFilters[index].from = newValue.toDate().toDateString();
      modifiedFilters[index].to = modifiedFilters[index].to || (new Date(Date.now() + (1000 * 60 * 60 * 24))).toDateString();
    } else {
      modifiedFilters[index].to = newValue.toDate().toDateString();
    }
    updateFilters([...modifiedFilters, ...buttonFilters]);
  }

  const handleHeaderChange = (event, index) => {
    const possibleHeaders = headers.filter((header) => header.id === event.target.value);
    if (possibleHeaders.length > 0) {
      modifiedFilters[index].header = possibleHeaders[0];
    }
    updateFilters([...modifiedFilters, ...buttonFilters]);
  }

  const handleAdd = () => {
    updateFilters([...modifiedFilters, ...buttonFilters, {}]);
  }

  const handleRemove = (index) => {
    modifiedFilters.splice(index, 1);
    updateFilters([...modifiedFilters, ...buttonFilters]);
  }

  const handleClear = () => {
    setModifiedFilters([{}]);
    setButtonFilters([]);
    setActionFilter(null);
    updateFilters([]);
  }

  const handleClickButtonFilter = (event, action) => {
    const filterValue = actionFilter === "none" ? null : actionFilter;
    let updatedButtonFilters = buttonFilters.filter((filter) => filter.value !== filterValue);

    if (actionFilter !== action) {
      if (action === "archive" && !showArchived && handleArchivedToggle) {
        handleArchivedToggle();
      }

      updatedButtonFilters.push({ header: { id: 'buttons', type: HeaderDataTypes.BUTTONS, display: true }, value: action === "none" ? null : action });
      setActionFilter(action);
    } else {
      setActionFilter(null);
    }

    setButtonFilters(updatedButtonFilters);
    updateFilters([...modifiedFilters, ...updatedButtonFilters]);
  }

  useEffect(() => {
    if (filters && filters.length > 0) {
      setModifiedFilters(filters.filter((filter) => filter?.header?.type !== HeaderDataTypes.BUTTONS));
    } else {
      setModifiedFilters([{}]);
    }
  }, [filters]);

  const getFilterInput = (header, index) => {
    switch (header?.type) {
      case HeaderDataTypes.DATE:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={classes.dates}>
              {isMobile ? <>
                <MobileDatePicker
                  label="From"
                  inputFormat="YYYY-MM-DD"
                  value={modifiedFilters[index] && modifiedFilters[index].from ? dayjs(new Date(modifiedFilters[index].from)) : dayjs(new Date())}
                  onChange={(newValue) => handleFilterDateChange(newValue, index, true)}
                  renderInput={(params) => <TextField {...params} InputProps={{ classes: { input: classes.resize, } }} />}
                />
                <MobileDatePicker
                  label="To"
                  inputFormat="YYYY-MM-DD"
                  value={modifiedFilters[index] && modifiedFilters[index].to ? dayjs(new Date(modifiedFilters[index].to)) : dayjs(new Date())}
                  onChange={(newValue) => handleFilterDateChange(newValue, index, false)}
                  renderInput={(params) => <TextField {...params} InputProps={{ classes: { input: classes.resize, } }} />}
                />
              </>
                : <>
                  <DesktopDatePicker
                    label="From"
                    inputFormat="YYYY-MM-DD"
                    value={modifiedFilters[index] && modifiedFilters[index].from ? dayjs(new Date(modifiedFilters[index].from)) : dayjs(new Date())}
                    onChange={(newValue) => handleFilterDateChange(newValue, index, true)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <div className={classes.space}></div>
                  <DesktopDatePicker
                    label="To"
                    inputFormat="YYYY-MM-DD"
                    value={modifiedFilters[index] && modifiedFilters[index].to ? dayjs(new Date(modifiedFilters[index].to)) : dayjs(new Date())}
                    onChange={(newValue) => handleFilterDateChange(newValue, index, false)}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </>
              }
            </div>
          </LocalizationProvider>
        );
      default:
        return (
          <TextField
            value={modifiedFilters[index] && modifiedFilters[index].value ? modifiedFilters[index].value : ""}
            label="Value"
            onChange={(event) => handleFilterValueChange(event, index)}
            InputProps={{
              classes: {
                input: classes.resize,
              }
            }}
          />
        );
    }
  }

  const getFilter = (index) => {
    if (headers && modifiedFilters) {
      if (index >= modifiedFilters.length) {
        modifiedFilters.push({});
      }
      return (
        <div className={classes.filterRow}>
          <FormControl className={classes.formControl}>
            <InputLabel id={`header-dropdown-${index}`}>Header</InputLabel>
            <Select
              labelId={`header-dropdown-${index}`}
              value={modifiedFilters[index] && modifiedFilters[index]?.header?.id ? modifiedFilters[index].header.id : ""}
              label="Header"
              onChange={(event) => handleHeaderChange(event, index)}
              inputProps={{
                classes: {
                  select: classes.resize,
                },
              }}
            >
              {headers.filter((header) => header.type !== HeaderDataTypes.BUTTONS).map((header) => {
                return (
                  <MenuItem value={header.id}>{header.label}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            {getFilterInput(modifiedFilters[index]?.header, index)}
          </FormControl>
          {
            index === modifiedFilters.length - 1 ?
              (<IconButton onClick={() => handleAdd()} disabled={shouldDisableFilter(index)}>
                <AddIcon />
              </IconButton>) :
              (<IconButton onClick={() => handleRemove(index)} >
                <RemoveIcon />
              </IconButton>)
          }

        </div>
      )
    }
  }

  return (
    <>
      <Button onClick={(event) => handleFilterClick(event)} variant="outlined" className={classes.filterButton}>
        <FilterListIcon />
        <span>
          Filters
        </span>
        {toggleArrow()}
      </Button>
      <Popover
        open={dropdownOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableEnforceFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper variant="elevation" >
          <Box sx={{ padding: 2 }}>
            <Typography>Filters</Typography>
            {modifiedFilters.map((filter, index) => getFilter(index))}
            <Box sx={{display: 'flex'}}>
              {ButtonFilters ?
                <ButtonFilters handleClickButtonFilter={handleClickButtonFilter} actionFilter={actionFilter} />
                : null}
              <Box sx={{ marginLeft: 'auto' }}>
                <Tooltip title="Clear filters">
                  <IconButton onClick={handleClear} disabled={filters.length < 1}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
}